// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

// Sweet Alert2
//@import 'sweetalert2/src/sweetalert2';
@import 'kmwine-sweetalert2';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| CUSTOM ||============================== //

.cleave-phone {
  display: block;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  background-color: #fafafa;
  line-height: 30px;
  vertical-align: middle;
  height: 40.12px;
  width: 100%;
  font-size: 14px;
  padding: 0 8px;
  margin-bottom: 15px;

  &::placeholder {
    font-size: 13px;
    font-weight: bold;
    color: #dfdfdf;
  }

  &:hover {
    border: 1px solid #616161;
  }

  &:focus {
    border: 1px solid #2196f3;
  }
}

// 말줄임표 1줄
.line-1-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 말줄임표 2줄
.line-2-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 말줄임표 3줄
.line-3-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 샵정보 [START]
#business-hours-table {
  margin-top: 20px;

  thead {
    tr {
      td {
        h5 {
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 10px;

        &.biz-hour {
          padding: 5px 2px 5px 10px;
        }

        &.biz-min {
          padding: 5px 10px 5px 2px;
        }
      }
    }
  }
}

// 샵정보 [END]

// 추천 상품 목록 [START]
.product-list-header-grid {
  width: 100%;
  padding-left: 16px;
  padding-right: 36px;
  display: flex;
  height: 50px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.recommend-product-list-grid,
.recommend-product-list-grid-2 {
  position: relative;
  padding: 0;
  margin: 0;

  .sortable-item {
    height: 84px;
    background-color: #fff;
    border: 1px solid #efefef;
    margin-bottom: 3px;
    list-style: none;

    div {
      &.recommend-product-img-grid {
        display: grid;
      }

      .recommend-product-name {
        display: inline;
        height: 72px;
      }

      .recommend-product-img {
        width: 100%;
        justify-content: center;

        .MuiAvatar-root {
          width: 100%;
          height: 80px;

          img {
            object-fit: contain;
          }
        }
      }

      .recommend-product-volume {
        text-align: center;
      }

      .recommend-product-drag-sort {
        justify-content: center;
      }
    }
  }
}

.sortable-item {
  &.SortableHelper {
    list-style: none;
    height: 80px;

    .MuiButtonBase-root {
      height: 100%;

      .recommend-product-img-grid {
        text-align: center;

        .recommend-product-img {
          .MuiAvatar-root {
            width: 100%;
            height: 80px;

            img {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

// 추천 상품 목록 [END]

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  width: 400px;
  height: 600px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}

.SortableItem {
  border: 1px solid #e5e5e5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

// 와인 pdata 추가 [START]
.pdata-create-detail-img-box {
  width: 20%;
  padding: 0 2px;

  .pdata-create-detail-img {
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 100%;
  }

  .pdata-create-detail-delete-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center;
    line-height: 20px;
    z-index: 1;
    cursor: pointer;

    &:after {
      content: '\2716';
      font-size: 14px;
      color: white;
    }
  }
}

// 와인 pdata 추가 [END]

// 말줄임표
.ellipsis1Line {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 메세지 템플릿 관리 [START]
.message-template-list-grid {
  position: relative;
  padding: 0;
  margin: 0;

  .sortable-item {
    height: 55px;
    background-color: #fff;
    border: 1px solid #efefef;
    margin-bottom: 3px;
    list-style: none;
    align-self: center;

    .recommend-product-drag-sort {
      display: flex;
      justify-content: center;
    }

    .message-template-title {
      display: inline;
    }
  }
}
.slick-list {
  height: 100%;

  .slick-track {
    height: 100%;

    div {
      height: 100%;

      .react-transform-wrapper {
        height: 100%;
      }
    }
  }
}

// 메세지 템플릿 관리 [END]

// 디스커버리 관리 [START]
.discovery-list-grid {
  position: relative;
  padding: 0;
  margin: 0;

  .sortable-item {
    height: 35px;
    background-color: #fff;
    border: 1px solid #efefef;
    margin-bottom: 3px;
    list-style: none;
    align-self: center;

    .discovery-drag-sort {
      display: flex;
      justify-content: center;
    }

    .discovery-title {
      display: inline;
      color: red;
    }
  }
}
// 디스커버리 관리 [END]

// 상세 이미지 확대
.MuiPaper-root {
  .react-transform-wrapper {
    width: 100%;
    height: 100%;

    .react-transform-component {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 말줄임표 1줄
.line-1-ellipsis {
  white-space: normal;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 말줄임표 2줄
.line-2-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 정산관리 [START]
#calculator-sum-table {
  width: 100%;
  border: 1px solid #e5e5e5;

  table {
    thead {
      border-bottom: 1px solid #eeeeee;

      tr {
        th {
          background-color: #eeeeee;
          border-right: 1px solid #e5e5e5;
          padding: 2px 6px;
        }
        th:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      tr {
        td {
          border-right: 1px solid #eeeeee;
          padding: 2px 6px;
        }
        td:last-child {
          border-right: none;
        }
      }
    }
  }
}
#calculator-table-paper {
  width: 100%;
  overflow-y: hidden;
  border-radius: 0;
  border: none;

  .calculator-table-container {
    max-height: 440px;
    border: 1px solid #e5e5e5;

    &.vendor {
      table {
        tbody {
          tr {
            td {
              padding: 10.5px 6px;
            }
          }
        }
      }
    }

    &.completed {
      max-height: 685px;
      //max-height: 100%;

      table {
        tbody {
          tr {
            td {
              padding: 8px;
            }
          }
        }
      }
    }

    table {
      thead {
        border-bottom: 1px solid black;

        tr {
          th {
            background-color: #eeeeee;
            border-right: 1px solid #e5e5e5;
            padding: 2px 6px;

            //&.change-color {
            //  background-color: #EDF6FE;
            //}
          }
          th:last-child {
            border-right: none;
          }
        }
      }

      tbody {
        tr {
          td {
            border-right: 1px solid #eeeeee;
            padding: 2px 6px;
          }
          td:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}
// 정산관리 [END]

// 메인진열 카드 관리 [START]
.curation-section-item {
  display: contents;

  .card-item {
    padding: 16px;
    border: 1px solid $grey50;
    background: $grey50;
    display: inline-block;
    margin-right: 8px;

    .card-bg-box {
      aspect-ratio: 1/1;
      border-right: 14px;
      background-size: cover;
      background-position: center center;
      border-radius: 14px;

      .card-title-box {
        font-family: NanumSquare;
        line-height: 1.5;
        position: absolute;
        padding: 18px;
        font-size: 21px;
        color: #fff;
        font-weight: 900;
        white-space: pre-wrap;
      }
    }
  }
}
// 메인진열 카드 관리 [END]

// 대표 리뷰 카드 관리 [START]
.repre-review-card {
  display: contents;

  .card-item {
    padding: 16px;
    border: 1px solid $grey50;
    background: $grey50;
    display: inline-block;
    margin-right: 8px;

    .card-bg-box {
      aspect-ratio: 1/1;
      border-right: 14px;
      background-size: cover;
      background-position: center center;
      border-radius: 14px;
    }
  }
}
// 대표 리뷰 카드 관리 [END]

.banner-sort-card {
  width: 100%;
  height: 380px;
  padding-right: 16px;
  padding-bottom: 16px;
}

// 대표 리뷰 이미지 관리 [START]
.repre-review-image {
  cursor: pointer;
  width: 108px;
  height: 108px;
  margin-right: 5px;
  & .MuiCardContent-root {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.selected {
  &:before {
    width: 107px;
    height: 106px;
    content: '';
    position: absolute;
    padding: 0;
    background-color: #000;
    opacity: 0.4;
    border-radius: 8px;
    box-sizing: content-box;
  }
  .checkmark {
    display: inline-block;
    position: absolute;
    margin: 35px 0 0 45px;
    transform: rotate(45deg);
    height: 30px;
    width: 15px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid #fff;
  }
}
// 대표 리뷰 이미지 관리 [END]

.list-hover {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.img-drag-none {
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}